/*
 * @file: index.js
 * @description: App Configration
 * @date: 3 April 2022
 * @author: Mohit
 * */

import React, { useEffect } from 'react';
// import {
//     ToastsContainer,
//     ToastsStore,
//     ToastsContainerPosition
// } from 'react-toasts';
import { PersistGate } from 'redux-persist/es/integration/react';
import "react-datepicker/dist/react-datepicker.css";
// import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom/dist';
import configureStore from './config';
// import { createBrowserHistory } from 'history';
import { Auth } from './methods/auth';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Forgotpassword from './pages/Forgotpassword';
import Resetpassword from './pages/Resetpassword';
import Profile from './pages/Profile'
import Roleslist from './pages/Roleslist'
import Settings from './pages/Settings';
import ContentManagement from './pages/ContentManagement';
import './scss/main.scss';
import "react-pagination-js/dist/styles.css"; // import css
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ContactDetails from './pages/Settings/ContactDetails';
import DashboardPage from './pages/Dashboard/DashboardPage';
import Users from './pages/Users';
import AddEditUser from './pages/Users/AddEditUser';
import UserDetail from './pages/Users/Profiledetail';
import Categories from './pages/Categories';
import AddEditCategory from './pages/Categories/AddEdit';
import CategoryDetail from './pages/Categories/Detail';
import ResellerCategories from './pages/ResellerCategories';
import ResellerCategoryDetail from './pages/ResellerCategories/Detail';
import AddEditResellerCategory from './pages/ResellerCategories/AddEdit';
import Employees from './pages/Employees';
import Roles from './pages/Roles';
import AddEditRole from './pages/Roles/AddEdit';
import AddEditContinent from './pages/Continents/AddEditContinent';
import Continent from './pages/Continents/index'
import Countriess from './pages/Countries';
import AddEditCountry from './pages/Countries/AddEditCountry';
import CountryDetail from './pages/Countries/Detail';
import Cities from './pages/CityPages';
import AddEditCity from './pages/CityPages/AddEditCity';
import CityDetail from './pages/CityPages/Detail';
import Plans from './pages/Plans';
import AddEditPlan from './pages/Plans/AddEdit';
import Features from './pages/Features';
import AddEditFeature from './pages/Features/AddEdit';
import Currency from './pages/CurrencyPages';
import AddEditCurrency from './pages/CurrencyPages/AddEditCurrency';
import CurrencyDetail from './pages/CurrencyPages/Detail';
import Coupons from './pages/Coupons';
import CouponsDetail from './pages/Coupons/Detail/index'
// import Department from './pages/Department';
import AddEditCoupon from './pages/Coupons/AddEdit';
import AddEditDepartment from './pages/Department/AddEdit';
import departmentDetail from './pages/Department/Detail/index' 

import EmailTemplate from './pages/EmailTemplate';
import AddEditEmailTemplate from './pages/EmailTemplate/AddEdit';
import Regions from './pages/Regions';
import AddEditRegion from './pages/Regions/AddEdit';
import BookingSystem from './pages/BookingSystem';
import AddEditBookingSystem from './pages/BookingSystem/AddEdit';
import Types from './pages/CategoriesType';
import AddEditTypes from './pages/CategoriesType/AddEdit';
import ApplyCurrency from './pages/CurrencyPages/Applycurrencys';
import POS from './pages/POS';
import AddEditPOS from './pages/POS/AddEdit';
import Orders from './pages/POS/Orders/Orders';
import Holiday from './pages/Holiday';
import AddEditPrice from './pages/DynamicPrice/AddEdit';
import DynamicPrice from './pages/DynamicPrice';
import AddEditEarlyBirdPricing from './pages/DynamicPrice/Early/AddEdit';
import AddEditEarlyBirdTimeFrames from './pages/DynamicPrice/TimeFrames/AddEdit';
import AddEditEarlyBirdInventory from './pages/DynamicPrice/Inventory/AddEdit';
import AddEditProductCategory from './pages/Categories/ProductCategory/AddEdit';
import ProductCategory from './pages/Categories/ProductCategory/Product';
import AddEditLastMinuteFixedDates from './pages/DynamicPrice/LastMinuteFixedDates/AddEdit';
import AddEditLastMinuteInventory from './pages/DynamicPrice/LastMinuteInventory/AddEdit';
import Customer from "./pages/Customers";
import AddEditCustomer from "./pages/Customers/AddEdit";
import AddEditAffiliate from "./pages/Affiliate/AddEdit";
import ViewCustomer from "./pages/Customers/Profiledetail";
import ViewAffiliate from "./pages/Affiliate/Profiledetail";
import Reseller from './pages/ResellerPages';
import AddEditReseller from './pages/ResellerPages/AddEditReseller';
import CRM from "./pages/CRM";
import ViewCRM from "./pages/CRM/View";
import AddEditCRM from "./pages/CRM/AddEdit";
import LogoSetting from './pages/Setting/index';

import 'react-phone-input-2/lib/style.css'
import Reviews from './pages/Reviews/index';


import AccountingSystem from "./pages/AccountingSystem";
import AddEditAccountingSystem from "./pages/AccountingSystem/AddEdit";

import Holidays from './pages/Holidays';
import AddEditHolidays from './pages/Holidays/AddEdit';
import RefundReason from "./pages/RefundReason";
import RefundReasonAddEdit from "./pages/RefundReason/AddEdit";
import Waiver from './pages/Waiver';
import AddEditWaiver from './pages/Waiver/AddEdit';
import ViewWaiver from './pages/Waiver/View';
import SustainableCategory from './pages/Categories/SutainableCategory/Product';
import AddEditSustanabilityCategory from './pages/Categories/SutainableCategory/AddEdit';
import Department from './pages/Department'
import Leads from './pages/LeadModules';
import AddEditLeads from './pages/LeadModules/AddEdit';
import MarketingSystem from './pages/MarketingChannel';
import AddEditMarketing from './pages/MarketingChannel/AddEdit';
import CommunicationChannel from './pages/CommunicationChannel';
import AddEditCommunication from './pages/CommunicationChannel/AddEdit';
import PricingCategory from './pages/Categories/PricingCategory/Product';
import AddEditPricingCategory from './pages/Categories/PricingCategory/AddEdit';
import { CostingURL } from './pages/CostingTemplate/Api';
import CostingTemplates from './pages/CostingTemplate';
import AddEditCostingTemplate from './pages/CostingTemplate/AddEditCostingTemplate';
import AddEditCostingTemp from './pages/CostingTemplate/AddEdit';
import LogoSettings from './pages/SiteSettings';
import ApiClient from './methods/api/apiClient';
import methodModel from './methods/methods';
import { CostingTourURL } from './pages/CostPerTourTemplate/Api';
import CostingTourTemplates from './pages/CostPerTourTemplate';
import AddEditCostingTourTemp from './pages/CostPerTourTemplate/AddEdit';
import Affiliate from './pages/Affiliate';
import KeySetting from './pages/KeysSettings/KeySetting';
// export const history = createBrowserHistory();
/************ store configration *********/
const { persistor, store } = configureStore(history);

export default () => {
    useEffect(()=>{
        console.log('Build Veirfied')
        ApiClient.get('api/setting/site').then(res=>{
            if(res.success){
            if(res?.data?.fav_icon){
                document.getElementById('iconData').href = methodModel.userImg(res?.data?.fav_icon)
            }
            if(res?.data?.logo){
                    localStorage.setItem('logo',res?.data?.logo)
                }
            }
        })
    },[])

    return (<>
        <Provider store={store}>
            <PersistGate loading={'loading ...'} persistor={persistor}>
                {/* <ConnectedRouter history={history}> */}
                    <Router>
                        <Routes>
                            <Route exact={true} path="/dashboard" store={store} requireAuth={Auth} element={<Dashboard/>} />
                            <Route exact={true} path="/employees" store={store} requireAuth={Auth} element={<Employees/>} />
                            <Route exact={true} path="/dashboardpage" store={store} requireAuth={Auth} element={<DashboardPage/>} />
                            <Route exact={true} store={store} path="/reviews/edit/:id" requireAuth={Auth} element={<LogoSetting/>} />
                            <Route exact={true} store={store} path="/setting" requireAuth={Auth} element={<LogoSetting/>} />
                            <Route exact={true} path="/settings/appointment/contact-details" store={store} requireAuth={Auth} element={<ContactDetails/>} />
                            <Route exact={true} path="/rolelist" store={store} requireAuth={Auth} element={<Roleslist/>} />
                            <Route exact={true} path="/pos" store={store} requireAuth={Auth} element={<POS/>} />
                            <Route exact={true} path="/pos/orders" store={store} requireAuth={Auth} element={<Orders/>} />
                            <Route exact={true} path="/pos/add" store={store} requireAuth={Auth} element={<AddEditPOS/>} />
                            <Route exact={true} path="/pos/edit/:id" store={store} requireAuth={Auth} element={<AddEditPOS/>} />
                            <Route exact={true} path="/users" store={store} requireAuth={Auth} element={<Users/>} />
                            <Route exact={true} path="/customer" store={store} requireAuth={Auth} element={<Customer/>} />
                            <Route exact={true} path="/affiliates" store={store} requireAuth={Auth} element={<Affiliate/>} />
                            <Route exact={true} path="/affiliates/add" store={store} requireAuth={Auth} element={<AddEditAffiliate/>} />
                            <Route exact={true} path="/affiliates/edit/:id" store={store} requireAuth={Auth} element={<AddEditAffiliate/>} />
                            <Route exact={true} path="/affiliates/view/:id" store={store} requireAuth={Auth} element={<ViewAffiliate/>} />
                            <Route exact={true} path="/customer/add" store={store} requireAuth={Auth} element={<AddEditCustomer/>} />
                            <Route exact={true} path="/customer/edit/:id" store={store} requireAuth={Auth} element={<AddEditCustomer/>} />
                            <Route exact={true} path="/customer/view/:id" store={store} requireAuth={Auth} element={<ViewCustomer/>} />
                            
                            <Route exact={true} path="/roles" store={store} requireAuth={Auth} element={<Roles/>} />
                            <Route exact={true} path="/roles/add" store={store} requireAuth={Auth} element={<AddEditRole/>} />
                            <Route exact={true} path="/roles/edit/:id" store={store} requireAuth={Auth} element={<AddEditRole/>} />
                            <Route exact={true} path="/users/add" store={store} requireAuth={Auth} element={<AddEditUser/>} />
                            <Route exact={true} path="/users/:role" store={store} requireAuth={Auth} element={<Users/>} />
                            <Route exact={true} path="/users/edit/:id" store={store} requireAuth={Auth} element={<AddEditUser/>} />
                            <Route exact={true} path="/users/:role/add" store={store} requireAuth={Auth} element={<AddEditUser/>} />
                            <Route exact={true} path="/users/:role/edit/:id" store={store} requireAuth={Auth} element={<AddEditUser/>} />
                            <Route exact={true} path="/userDetail/:id" store={store} requireAuth={Auth} element={<UserDetail/>} />
                            <Route exact={true} path="/categories" store={store} requireAuth={Auth} element={<Categories/>} />
                            <Route exact={true} path="/categories/add" store={store} requireAuth={Auth} element={<AddEditCategory/>} />
                            <Route exact={true} path="/categories/edit/:id" store={store} requireAuth={Auth} element={<AddEditCategory/>} />
                            <Route exact={true} path="/category/:type" store={store} requireAuth={Auth} element={<Categories/>} />
                            <Route exact={true} path="/category/:type/add" store={store} requireAuth={Auth} element={<AddEditCategory/>} />
                            <Route exact={true} path="/category/product/:type" store={store} requireAuth={Auth} element={<ProductCategory/>} />
                            <Route exact={true} path="/category/product/:type/add" store={store} requireAuth={Auth} element={<AddEditProductCategory/>} />
                            <Route exact={true} path="/category/product/:type/edit/:id" store={store} requireAuth={Auth} element={<AddEditProductCategory/>} />
                            

                            {/*  -------------------------------------------------------- */}
                            <Route exact={true} path="/category/sustanability/:type" store={store} requireAuth={Auth} element={<SustainableCategory/>} />
                            <Route exact={true} path="/category/sustanability/:type/add" store={store} requireAuth={Auth} element={<AddEditSustanabilityCategory/>} />
                            <Route exact={true} path="/category/sustanability/:type/edit/:id" store={store} requireAuth={Auth} element={<AddEditSustanabilityCategory/>} />


                            <Route exact={true} path="/category/pricing/:type" store={store} requireAuth={Auth} element={<PricingCategory/>} />
                            <Route exact={true} path="/category/pricing/:type/add" store={store} requireAuth={Auth} element={<AddEditPricingCategory/>} />
                            <Route exact={true} path="/category/pricing/:type/edit/:id" store={store} requireAuth={Auth} element={<AddEditPricingCategory/>} />
                            

                            <Route exact={true} path="/category/pricing/:type" store={store} requireAuth={Auth} element={<SustainableCategory/>} />
                            <Route exact={true} path="/category/pricing/:type/add" store={store} requireAuth={Auth} element={<AddEditSustanabilityCategory/>} />
                            <Route exact={true} path="/category/pricing/:type/edit/:id" store={store} requireAuth={Auth} element={<AddEditSustanabilityCategory/>} />

                            <Route exact={true} path="/category/:type/edit/:id" store={store} requireAuth={Auth} element={<AddEditCategory/>} />
                            <Route exact={true} path="/reseller-categories" store={store} requireAuth={Auth} element={<ResellerCategories/>} />
                            <Route exact={true} path="/reseller-categories/add" store={store} requireAuth={Auth} element={<AddEditResellerCategory/>} />
                            <Route exact={true} path="/reseller-categories/edit/:id" store={store} requireAuth={Auth} element={<AddEditResellerCategory/>} />
                            <Route exact={true} path="/reseller-categories/detail/:id" store={store} requireAuth={Auth} element={<ResellerCategoryDetail/>} />
                            <Route exact={true} path="/categoryDetail/:id" store={store} requireAuth={Auth} element={<CategoryDetail/>} />
                            <Route exact={true} path="/content" store={store} requireAuth={Auth} element={<ContentManagement/>} />
                            <Route exact={true} path="/profile" store={store} requireAuth={Auth} element={<Profile/>} />
                            <Route exact={true} path="/profile/:tab" store={store} requireAuth={Auth} element={<Settings/>} />
                            <Route exact={true} path="/login" store={store} requireAuth={Auth} element={<Login/>} />
                            <Route exact={true} path="/forgotpassword" store={store} requireAuth={Auth} element={<Forgotpassword/>} />
                            <Route exact={true} path="/resetpassword" store={store} requireAuth={Auth} element={<Resetpassword/>} />
                            {/* Categories Type */}
                            <Route exact={true} path="/types" store={store} requireAuth={Auth} element={<Types/>} />
                            <Route exact={true} path="/types/add" store={store} requireAuth={Auth} element={<AddEditTypes/>} />
                            <Route exact={true} path="/types/edit/:id" store={store} requireAuth={Auth} element={<AddEditTypes/>} />
                            {/* Feature */}
                            <Route exact={true} path="/features" store={store} requireAuth={Auth} element={<Features/>} />
                            <Route exact={true} path="/features/add" store={store} requireAuth={Auth} element={<AddEditFeature/>} />
                            <Route exact={true} path="/features/edit/:id" store={store} requireAuth={Auth} element={<AddEditFeature/>} />
                            {/* Continents */}
                            <Route exact={true} path="/continents" store={store} requireAuth={Auth} element={<Continent/>} />
                            <Route exact={true} path="/continents/add" store={store} requireAuth={Auth} element={<AddEditContinent/>} />
                            <Route exact={true} path="/continents/edit/:id" store={store} requireAuth={Auth} element={<AddEditContinent/>} />
                            {/* Plans */}
                            <Route exact={true} path="/plans" store={store} requireAuth={Auth} element={<Plans/>} />
                            <Route exact={true} path="/plans/add" store={store} requireAuth={Auth} element={<AddEditPlan/>} />
                            <Route exact={true} path="/plans/edit/:id/:copy" store={store} requireAuth={Auth} element={<AddEditPlan/>} />
                            {/* Booking System */}
                            <Route exact={true} path="/bookingSystem" store={store} requireAuth={Auth} element={<BookingSystem/>} />
                            <Route exact={true} path="/bookingSystem/add" store={store} requireAuth={Auth} element={<AddEditBookingSystem/>} />
                            <Route exact={true} path="/bookingSystem/edit/:id" store={store} requireAuth={Auth} element={<AddEditBookingSystem/>} />
                            {/* Marketing Channel */}
                            <Route exact={true} path="/marketing" store={store} requireAuth={Auth} element={<MarketingSystem/>} />
                            <Route exact={true} path="/marketing/add" store={store} requireAuth={Auth} element={<AddEditMarketing/>} />
                            <Route exact={true} path="/marketing/edit/:id" store={store} requireAuth={Auth} element={<AddEditMarketing/>} />

                            {/* COmmunication Channel */}
                            <Route exact={true} path="/communication" store={store} requireAuth={Auth} element={<CommunicationChannel/>} />
                            <Route exact={true} path="/communication/edit/:id" store={store} requireAuth={Auth} element={<AddEditCommunication/>} />
                            {/* Accounting System */}
                            <Route exact={true} path="/accountingSystem" store={store} requireAuth={Auth} element={<AccountingSystem/>} />
                            <Route exact={true} path="/accountingSystem/add" store={store} requireAuth={Auth} element={<AddEditAccountingSystem/>} />
                            <Route exact={true} path="/accountingSystem/edit/:id" store={store} requireAuth={Auth} element={<AddEditAccountingSystem/>} />
                            {/* Coupon */}
                            <Route exact={true} path="/coupon" store={store} requireAuth={Auth} element={<Coupons/>} />
                            <Route exact={true} path="/coupon/add" store={store} requireAuth={Auth} element={<AddEditCoupon/>} />
                            <Route exact={true} path="/coupon/edit/:id" store={store} requireAuth={Auth} element={<AddEditCoupon/>} />
                            <Route exact={true} path="/coupon/view/:id" store={store} requireAuth={Auth} element={<CouponsDetail/>} />


                           
                             {/*Department*/}    
                             <Route exact={true} path="/department" store={store} requireAuth={Auth} element={<Department/>} />
                            
                            <Route exact={true} path="/department/add" store={store} requireAuth={Auth} element={<AddEditDepartment/>} />
                            
                            <Route exact={true} path="/department/view/:id" store={store} requireAuth={Auth} element={<departmentDetail/>} />
                            
                             {/* Holidays */}
                             <Route exact={true} path="/holidays" store={store} requireAuth={Auth} element={<Holidays/>} />
                            <Route exact={true} path="/holidays/add" store={store} requireAuth={Auth} element={<AddEditHolidays/>} />
                            <Route exact={true} path="/holidays/edit/:id" store={store} requireAuth={Auth} element={<AddEditHolidays/>} />
                            {/* Country */}
                            <Route exact={true} path="/countries" store={store} requireAuth={Auth} element={<Countriess/>} />
                            <Route exact={true} path="/countries/add" store={store} requireAuth={Auth} element={<AddEditCountry/>} />
                            <Route exact={true} path="/countries/edit/:id" store={store} requireAuth={Auth} element={<AddEditCountry/>} />
                            <Route exact={true} path="/countries/:id" store={store} requireAuth={Auth} element={<CountryDetail/>} />
                            {/* Region */}
                            <Route exact={true} path="/regions" store={store} requireAuth={Auth} element={<Regions/>} />
                            <Route exact={true} path="/regions/add" store={store} requireAuth={Auth} element={<AddEditRegion/>} />
                            <Route exact={true} path="/regions/edit/:id" store={store} requireAuth={Auth} element={<AddEditRegion/>} />
                            {/* City */}
                            <Route exact={true} path="/cities" store={store} requireAuth={Auth} element={<Cities/>} />
                            <Route exact={true} path="/cities/add" store={store} requireAuth={Auth} element={<AddEditCity/>} />
                            <Route exact={true} path="/cities/edit/:id" store={store} requireAuth={Auth} element={<AddEditCity/>} />
                            <Route exact={true} path="/cities/:id" store={store} requireAuth={Auth} element={<CityDetail/>} />
                          

                            <Route exact={true} path="/department/edit/:id" store={store} requireAuth={Auth} element={<AddEditDepartment/>} />
                            {/* Currency */}
                            <Route exact={true} path="/currency" store={store} requireAuth={Auth} element={<Currency/>} />
                            <Route exact={true} path="/currency/add" store={store} requireAuth={Auth} element={<AddEditCurrency/>} />
                            <Route exact={true} path="/currency/edit/:id" store={store} requireAuth={Auth} element={<AddEditCurrency/>} />
                            <Route exact={true} path="/currency/:id" store={store} requireAuth={Auth} element={<CurrencyDetail/>} />
                            {/* Apply Currency */}
                            <Route exact={true} path="/applycurrency" store={store} requireAuth={Auth} element={<ApplyCurrency/>} />
                            {/* Holiday */}
                            <Route exact={true} path="/holiday" store={store} requireAuth={Auth} element={<Holiday/>} />
                            {/* Last Minute Pricing */}
                            <Route exact={true} path="/dynamicprice/lastminutefixeddates/add" requireAuth={Auth} element={<AddEditLastMinuteFixedDates/>} />
                            <Route exact={true} path="/dynamicprice/lastminutefixeddates/edit/:id/:copy" requireAuth={Auth} element={<AddEditLastMinuteFixedDates/>} />
                            <Route exact={true} path="/dynamicprice/lastminuteinventory/add" requireAuth={Auth} element={<AddEditLastMinuteInventory/>} />
                            <Route exact={true} path="/dynamicprice/lastminuteinventory/edit/:id/:copy" requireAuth={Auth} element={<AddEditLastMinuteInventory/>} />
                            {/* Early Bird Pricing */}
                            <Route exact={true} path="/dynamicprice/earlybirdcustomdates/add" requireAuth={Auth} element={<AddEditEarlyBirdPricing/>} />
                            <Route exact={true} path="/dynamicprice/earlybirdcustomdates/edit/:id/:copy" requireAuth={Auth} element={<AddEditEarlyBirdPricing/>} />
                            <Route exact={true} path="/dynamicprice/earlybirdtimeframes/add" requireAuth={Auth} element={<AddEditEarlyBirdTimeFrames/>} />
                            <Route exact={true} path="/dynamicprice/earlybirdtimeframes/edit/:id/:copy" requireAuth={Auth} element={<AddEditEarlyBirdTimeFrames/>} />
                            <Route exact={true} path="/dynamicprice/earlybirdinventory/add" requireAuth={Auth} element={<AddEditEarlyBirdInventory/>} />
                            <Route exact={true} path="/dynamicprice/earlybirdinventory/edit/:id/:copy" requireAuth={Auth} element={<AddEditEarlyBirdInventory/>} />
                            {/* Dynamic Price */}
                            <Route exact={true} path="/dynamicpricelist" store={store} requireAuth={Auth} element={<DynamicPrice/>} />
                            <Route exact={true} path="/dynamicprice/add" store={store} requireAuth={Auth} element={<AddEditPrice/>} />
                            <Route exact={true} path="/dynamicprice/:type" store={store} requireAuth={Auth} element={<DynamicPrice/>} />
                            <Route exact={true} path="/dynamicprice/:type/add" store={store} requireAuth={Auth} element={<AddEditPrice/>} />
                            <Route exact={true} path="/dynamicprice/:type/edit/:id/:copy" store={store} requireAuth={Auth} element={<AddEditPrice/>} />

                            <Route exact={true} path="/crm" store={store} requireAuth={Auth} element={<CRM/>} />
                            <Route exact={true} path="/crm/add" store={store} requireAuth={Auth} element={<AddEditCRM/>} />
                            <Route exact={true} path="/crm/edit/:id" store={store} requireAuth={Auth} element={<AddEditCRM/>} />
                            <Route exact={true} path="/crm/view/:id" store={store} requireAuth={Auth} element={<ViewCRM/>} />


                            {/*  Here are the Routes for the Waiver Module  */}
                            
                            <Route exact={true} path="/waiver" store={store} requireAuth={Auth} element={<Waiver/>} />
                            <Route exact={true} path="/waiver/add" store={store} requireAuth={Auth} element={<AddEditWaiver/>} />
                            <Route exact={true} path="/waiver/edit/:id" store={store} requireAuth={Auth} element={<AddEditWaiver/>} />
                            <Route exact={true} path="/waiver/view/:id" store={store} requireAuth={Auth} element={<ViewWaiver/>} />

                            {/* Email Template */}
                            <Route exact={true} path="/emailtemplate" store={store} requireAuth={Auth} element={<EmailTemplate/>}/>
                            <Route exact={true} path="/emailtemplate/add" store={store} requireAuth={Auth} element={<AddEditEmailTemplate/>} />
                            <Route exact={true} path="/emailtemplate/edit/:id" store={store} requireAuth={Auth} element={<AddEditEmailTemplate/>} />

                            {/*  For Reseller  */}
                            <Route exact={true} path="/reseller" store={store} requireAuth={Auth} element={<Reseller/>}/>
                            <Route exact={true} path="/reviews" store={store} requireAuth={Auth} element={<Reviews/>}/>
                            <Route exact={true} path="/reseller/addedit/:id?" store={store} requireAuth={Auth} element={<AddEditReseller/>}/>

                            {/* Refund Reson */}
                            <Route exact={true} path="/refund-reason" store={store} requireAuth={Auth} element={<RefundReason/>} />
                            <Route exact={true} path="/refund-reason/add" store={store} requireAuth={Auth} element={<RefundReasonAddEdit/>} />
                            <Route exact={true} path="/refund-reason/edit/:id" store={store} requireAuth={Auth} element={<RefundReasonAddEdit/>} />

                            <Route exact={true} path="/leads" store={store} requireAuth={Auth} element={<Leads/>} />
                            <Route exact={true} path="/leads/add/:id?" store={store} requireAuth={Auth} element={<AddEditLeads/>} />
                            {/*  ROUTES FOR COSTING TEMPLATE MODULE  */}
                            <Route
                                exact={true}
                                path={CostingURL.list}
                                store={store}
                                requireAuth={Auth}
                                element={<CostingTemplates/>}
                            />
                            <Route
                                exact={true}
                                path={CostingURL.add}
                                store={store}
                                requireAuth={Auth}
                                element={<AddEditCostingTemp/>}
                            />
                            <Route
                                exact={true}
                                path={`${CostingURL.edit}/:id`}
                                store={store}
                                requireAuth={Auth}
                                element={<AddEditCostingTemp/>}
                            />


                            {/*  ROUTES FOR COSTING PER TOUR TEMPLATE MODULE  */}
                            <Route
                                exact={true}
                                path={CostingTourURL.list}
                                store={store}
                                requireAuth={Auth}
                                element={<CostingTourTemplates/>}
                            />
                            <Route
                                exact={true}
                                path={CostingTourURL.add}
                                store={store}
                                requireAuth={Auth}
                                element={<AddEditCostingTourTemp/>}
                            />
                            <Route
                                exact={true}
                                path={`${CostingTourURL.edit}/:id`}
                                store={store}
                                requireAuth={Auth}
                                element={<AddEditCostingTourTemp/>}
                            />
                            <Route
                            element={<KeySetting/>}
                            path='/keys/settings'
                            store={store}
                            requireAuth={Auth}                             
                            />

                            <Route
                                exact={true}
                                path={`/web/settings`}
                                store={store}
                                requireAuth={Auth}
                                element={<LogoSettings/>}
                            />
                            

                            <Route exact store={store} path="/" element={<Login />}>
                                {/* <Navigate to="/login" /> */}
                            </Route>
                        </Routes>
                    </Router>
                {/* </ConnectedRouter> */}
            </PersistGate>
        </Provider>
        <div id="loader" className="loaderDiv d-none">
            <div>
                <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
            </div>
        </div>
        {/* <ToastsContainer
            position={ToastsContainerPosition.TOP_RIGHT}
            store={ToastsStore}
        /> */}
    </>
    );
};

import React, { useEffect, useState } from 'react'
import ApiClient from '../../methods/api/apiClient';
import Layout from '../../components/global/layout';
import loader from '../../methods/loader';
import { HiOutlineArrowDown } from 'react-icons/hi';

export default function KeySetting() {
  const [DisabledButton,setDisabledButton]=useState(true);
    const [KeysList,setKeysList]=useState([]);
    const GetKeyLists=()=>{
        ApiClient.get(`api/mapped-keys`).then(res=>{
            if(res.success){
                setKeysList(res.data)
            }
        })
    }
useEffect(()=>{
GetKeyLists()
},[])

const HandleInputChange=(keyName,index,value)=>{
    let data=KeysList
    data[index][keyName]=value;
    setKeysList([...data]);
    setDisabledButton(false)

}

const MappedKeySaver=(e)=>{
    e.preventDefault();
    loader(true)
  ApiClient.put(`api/mapped-key`,KeysList).then(res=>{
        if(res.success){

        }
        setDisabledButton(true)
        loader(false)
    })

}
    function formatKey(key) {
        // Replace underscores with spaces
        let formattedKey = key.replace(/_/g, ' ');

        // Split the key into words based on spaces and capital letters
        const words = formattedKey.match(/[A-Z][a-z]*|[a-z]+/g);

        // Capitalize each word and join them with spaces
        formattedKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return formattedKey;
    }
  return (
    <div >
      <Layout>
              <div className="flex justify-between items-center">

                  <div>
                      <h3 className="text-2xl font-semibold text-[#111827]">
                          Keys Mapping Setting
                      </h3>
                      <p class="text-sm font-normal text-[#75757A]">Here you can set the Keys</p>
                  </div>
                  <div className="flex filterFlex gap-2">

                  </div>



              </div>

        <div className='shadow-box w-full bg-white rounded-lg mt-6 border border-grey overflow-hidden'>

        
      <table className="w-full">
    <thead className="border-b border-[#EAECF0]">
        <tr className="border-b border-[#EAECF0]">
            <th scope="col" className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                Dazhboard Key Name
                
            </th>
            {KeysList.length > 0 && Object.keys(KeysList[0]).map(ObjectKeys => (
                (ObjectKeys !== 'id' && ObjectKeys !== '_id' && ObjectKeys !== 'dazhboardsKeyName') && (
                    <th scope="col" key={ObjectKeys} className="cursor-pointer capitalize text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                        {formatKey(ObjectKeys)}
                        <span className="ml-2"></span>
                    </th>
                )
            ))}
        </tr>
    </thead>
    <tbody>
        {KeysList.map((item, mainIndex) => (
            <tr key={mainIndex}>
                <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left capitalize border-[#EAECF0]">
                    {formatKey(item?.dazhboardsKeyName)}
                </td>
                {Object.keys(item).map(ObjectKeys => (
                    (ObjectKeys !== 'id' && ObjectKeys !== '_id' && ObjectKeys !== 'dazhboardsKeyName') && (
                        <td key={ObjectKeys} className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                            <input
                                required
                                disabled={ObjectKeys === 'dazhboardsKeyName'}
                                type="text"
                                value={item[ObjectKeys]}
                                onChange={e => HandleInputChange(ObjectKeys, mainIndex, e.target.value)}
                                className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                            />
                        </td>
                    )
                ))}
            </tr>
        ))}
    </tbody>
</table>
 <div className='flex items-center justify-end pb-4 px-3' >
            <button type="submit" disabled={DisabledButton} onClick={e => MappedKeySaver(e)}
                          className="!px-4  mt-3 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
             </div>
             </div>
      </Layout>
    </div>
  )
}

import React, { useState, useEffect } from 'react';


import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import methodModel from '../../methods/methods';
import { roleType } from '../../models/type.model';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/global/layout';
import statusModel from '../../models/status.model';
import SelectDropdown from '../../components/common/SelectDropdown';
import { Tooltip } from "antd";

const AddEditRole = () => {
  const defaultvalue = () => {
    let keys = { ...roleType };
    Object.keys(roleType).map((itm) => {
      if (itm != 'permissions') keys[itm] = '';
    });
    Object.keys(roleType.permissions).map((itm) => {
      keys.permissions[itm] = false;
    });
    keys.status = 'active';
    return keys;
  };
  const { id } = useParams();
  const [form, setform] = useState(roleType);
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: 'status', required: true },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    let method = 'post';
    let url = 'api/role';
    let value = {
      ...form,
    };
    if (value.id) {
      method = 'put';
      url = 'api/role/update';
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        // ToastsStore.success(res.message);
        history('/roles');
      }
      loader(false);
    });
  };

  const setpermission = (key, value) => {
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        [key]: value,
      },
    });
  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get('api/role/detail', { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          let payload = roleType;

          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });
          if (value.permissions) {
            payload.permissions = { ...value.permissions[0] };
            // payload.permissions={ ...payload.permissions,...value.permissions}
          }

          setform({
            ...payload,
          });
        }
        loader(false);
      });
    } else {
      setform(defaultvalue());
    }
  }, [id]);
  //  For Handling Category Checkbboxs
  const handleallCategorycheckbox = (check) => {
    let value = true
    if (check) {
      value = true
    }
    else {
      value = false
    };
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readCategory: value,
        addCategory: value,
        editCategory: value,
        deleteCategory: value
      },
    });

  }
  //  For Handling Dashboard permission
  const hanldealldashboardpermission = (check) => {
    let value = true
    if (check) { value = true }
    else {
      value = false
    };
    setform({ ...form, permissions: { ...form.permissions, readDashboard: value } });

  }
  //  For Handling Cutomer Permission
  const HandleCutomerPer = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readCustomer: value, addCustomer: value, deleteCustomer: value, editCustomer: value } });
  }
  //  For Handling Role Permission
  const handleRolesPer = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readRole: value, addRole: value, deleteRole: value, editRole: value } });
  }
  const handleAdminUser = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readAdmins: value, addAdmins: value, deleteAdmins: value, editAdmins: value } });
  }
  const handleTypesPer = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readTypes: value, addTypes: value, deleteTypes: value, editTypes: value } });
  }
  const handleResellCateg = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readResellerCategory: value, addResellerCategory: value, deleteResellerCategory: value, editResellerCategory: value } });
  }
  const handlePlanFeature = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readPlanFeatures: value, addPlanFeatures: value, deletePlanFeatures: value, editPlanFeatures: value } });
  }
  const handlePlanPre = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readPlan: value, addPlan: value, deletePlan: value, editPlan: value } });
  }
  const handleCoupons = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readCoupons: value, addCoupons: value, deleteCoupons: value, editCoupons: value } });
  }
  const handleCurrencyPre = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readCurrency: value, addCurrency: value, deleteCurrency: value, editCurrency: value } });
  }
  const handleBookingPre = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readBooking: value, addBooking: value, deleteBooking: value, editBooking: value, refreshBooking: value } });
  }
  const HandleContinent = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readContinents: value, addContinents: value, deleteContinents: value, editContinents: value } });
  }
  const handleCountriuesPre = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readCountries: value, addCountries: value, deleteCountries: value, editCountries: value } });
  }
  const HandleRegionPre = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readRegion: value, addRegion: value, deleteRegion: value, editRegion: value } });
  }
  const HandleCitiesPre = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readCities: value, addCities: value, deleteCities: value, editCities: value } });
  }
  const handleEmailPre = (check) => {
    let value = check ? true : false
    setform({ ...form, permissions: { ...form.permissions, readEmailTemplate: value, addEmailTemplate: value, editEmailTemplate: value } });
  }


  const HandleAll = (check) => {
    let value = check ? true : false;
    let permissions = form.permissions
    Object.keys(permissions).map(itm => {
      permissions[itm] = value
    })
    setform({ ...form, permissions: permissions });
  }

  const isAllChecked = () => {
    let value = true;
    let permissions = form.permissions
    Object.keys(permissions).map(itm => {
      if (!permissions[itm]) value = false
    })
    return value
  }

  const HandleAllRead = (check) => {
    let value = check ? true : false;
    setform({ ...form, permissions: { ...form.permissions, readAdmins: value, readBooking: value, readCategory: value, readCities: value, readContinents: value, readCountries: value, readCoupons: value, readCurrency: value, readCustomer: value, readDashboard: value, readEmailTemplate: value, readPlan: value, readPlanFeatures: value, readRegion: value, readResellerCategory: value, readRole: value, readTypes: value } });
  }
  const HandleAllAdd = (check) => {
    let value = check ? true : false;
    setform({ ...form, permissions: { ...form.permissions, addAdmins: value, addBooking: value, addCategory: value, addCities: value, addContinents: value, addCountries: value, addCoupons: value, addCurrency: value, addCustomer: value, addEmailTemplate: value, addPlan: value, addPlanFeatures: value, addRegion: value, addResellerCategory: value, addRole: value, addTypes: value } });
  }
  const HandleallEdit = (check) => {
    let value = check ? true : false;
    setform({ ...form, permissions: { ...form.permissions, editAdmins: value, editBooking: value, editCategory: value, editCities: value, editContinents: value, editCountries: value, editCoupons: value, editCurrency: value, editRole: value, editCustomer: value, editEmailTemplate: value, editPlan: value, editPlanFeatures: value, editRegion: value, editResellerCategory: value, editTypes: value } });
  }
  const HandleAllDelete = (check) => {
    let value = check ? true : false;
    setform({ ...form, permissions: { ...form.permissions, deleteAdmins: value, deleteBooking: value, deleteCategory: value, deleteCities: value, deleteContinents: value, deleteCountries: value, deleteCoupons: value, deleteCurrency: value, deleteCustomer: value, deletePlan: value, deletePlanFeatures: value, deleteRegion: value, deleteResellerCategory: value, deleteRole: value, deleteTypes: value } });
  }
  const HandleRefreshAll = (check) => {
    let value = check ? true : false;
    setform({ ...form, permissions: { ...form.permissions, refreshBooking: value } });
  }
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="shadow-box border !border-grey bg-white rounded-large">


            <div className='flex justify-between items-center p-6'>

              <div className='flex items-center'>
                <Tooltip placement="top" title="Back">
                  <Link to="/roles" className="!px-4 h-12 w-12  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border  transition-all    mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                </Tooltip>
                <div>
                  <h3 className="text-2xl font-semibold text-[#111827]">   {form && form.id ? 'Edit' : 'Add'} Role</h3>
                  <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Roles</p>
                </div>
              </div>

              <div className="w-80">
                <label>
                  Name<span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="relative shadow-box border border-grey bg-white min-w-[320px] rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                  value={form.name}
                  onChange={(e) => setform({ ...form, name: e.target.value })}
                  required
                />
              </div>
            </div>

            <div className="shadow-box w-full bg-white rounded-lg  ">
              <div className="scrollbar w-full overflow-auto">

                <div class="table_section tablepadding">
                  <p className='text-xl font-semibold text-[#111827] px-4 pb-2'>Permissions</p>
                  <table class="w-full">
                    <thead class="table_head roleTable">
                      <tr class="border-b border-[#EAECF0]">
                        <th scope="col" class="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}"></th>
                        <th scope="col" class="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}">
                          <input
                            type="checkbox" onChange={e => HandleAll(e.target.checked)} checked={isAllChecked()} />

                          All</th>
                        <th scope="col" class="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}">
                          <input
                            type="checkbox" onChange={e => HandleAllRead(e.target.checked)} checked={form.permissions.readAdmins && form.permissions.readBooking && form.permissions.readCategory && form.permissions.readCities && form.permissions.readContinents && form.permissions.readCountries && form.permissions.readCountries && form.permissions.readCoupons && form.permissions.readCurrency && form.permissions.readCustomer && form.permissions.readDashboard && form.permissions.readEmailTemplate && form.permissions.readPlan && form.permissions.readPlanFeatures && form.permissions.readRegion && form.permissions.readResellerCategory && form.permissions.readRole && form.permissions.readTypes} />Read</th>
                        <th scope="col" class="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}"> <input
                          type="checkbox" onChange={e => HandleAllAdd(e.target.checked)} checked={form.permissions.addAdmins && form.permissions.addBooking && form.permissions.addCategory && form.permissions.addCities && form.permissions.addContinents && form.permissions.addCountries && form.permissions.addCoupons && form.permissions.addCurrency && form.permissions.addCustomer && form.permissions.addEmailTemplate && form.permissions.addPlan && form.permissions.addPlanFeatures && form.permissions.addRegion && form.permissions.addResellerCategory && form.permissions.addRole && form.permissions.addTypes} />Add</th>
                        <th scope="col" class="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}"> <input
                          type="checkbox" onChange={e => HandleallEdit(e.target.checked)} checked={form.permissions.editAdmins && form.permissions.editBooking && form.permissions.editCategory && form.permissions.editCities && form.permissions.editContinents && form.permissions.editCountries && form.permissions.editCoupons && form.permissions.editCurrency && form.permissions.editCustomer && form.permissions.editEmailTemplate && form.permissions.editPlan && form.permissions.editPlanFeatures && form.permissions.editRegion && form.permissions.editResellerCategory && form.permissions.editRole && form.permissions.editTypes} />Edit</th>
                        <th scope="col" class="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}"> <input
                          type="checkbox" onChange={e => HandleAllDelete(e.target.checked)} checked={form.permissions.deleteAdmins && form.permissions.deleteBooking && form.permissions.deleteCategory && form.permissions.deleteCities && form.permissions.deleteContinents && form.permissions.deleteCountries && form.permissions.deleteCoupons && form.permissions.deleteCurrency && form.permissions.deleteCustomer && form.permissions.deletePlan && form.permissions.deletePlanFeatures && form.permissions.deleteRegion && form.permissions.deleteResellerCategory && form.permissions.deleteRole && form.permissions.deleteTypes} />Delete</th>
                        <th scope="col" class="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}"> <input
                          type="checkbox" onChange={e => HandleRefreshAll(e.target.checked)} checked={form.permissions.refreshBooking} />Refresh</th>
                      </tr>
                    </thead>
                    <tbody className='roleTable'>
                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Dashboard</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={(e) => hanldealldashboardpermission(e.target.checked)} checked={form.permissions.readDashboard} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><div className="checkList">



                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readDashboard}
                              onChange={(e) =>
                                setpermission('readDashboard', e.target.checked)
                              }

                            />{' '}

                          </label>
                        </div></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Customer</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => HandleCutomerPer(e.target.checked)} checked={form.permissions.readCustomer && form.permissions.addCustomer && form.permissions.editCustomer && form.permissions.deleteCustomer} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>





                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readCustomer}
                              onChange={(e) =>
                                setpermission(
                                  'readCustomer',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addCustomer}
                              onChange={(e) =>
                                setpermission('addCustomer', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editCustomer}
                              onChange={(e) =>
                                setpermission(
                                  'editCustomer',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteCustomer}
                              onChange={(e) =>
                                setpermission(
                                  'deleteCustomer',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>
                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Roles</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={(e) => handleRolesPer(e.target.checked)} checked={form.permissions.readRole && form.permissions.addRole && form.permissions.editRole && form.permissions.deleteRole} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readRole}
                              onChange={(e) =>
                                setpermission('readRole', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addRole}
                              onChange={(e) =>
                                setpermission('addRole', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editRole}
                              onChange={(e) =>
                                setpermission('editRole', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteRole}
                              onChange={(e) =>
                                setpermission('deleteRole', e.target.checked)
                              }
                            />{' '}

                          </label>

                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Users</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleAdminUser(e.target.checked)} checked={form.permissions.readAdmins && form.permissions.addAdmins && form.permissions.deleteAdmins && form.permissions.editAdmins} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readAdmins}
                              onChange={(e) =>
                                setpermission('readAdmins', e.target.checked)
                              }
                            />
                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addAdmins}
                              onChange={(e) =>
                                setpermission('addAdmins', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editAdmins}
                              onChange={(e) =>
                                setpermission('editAdmins', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteAdmins}
                              onChange={(e) =>
                                setpermission('deleteAdmins', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Types</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleTypesPer(e.target.checked)} checked={form.permissions.addTypes && form.permissions.editTypes && form.permissions.readTypes && form.permissions.deleteTypes} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readTypes}
                              onChange={(e) =>
                                setpermission('readTypes', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addTypes}
                              onChange={(e) =>
                                setpermission('addTypes', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editTypes}
                              onChange={(e) =>
                                setpermission('editTypes', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteTypes}
                              onChange={(e) =>
                                setpermission('deleteTypes', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Category</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={(e) => handleallCategorycheckbox(e.target.checked)} checked={form.permissions.readCategory && form.permissions.addCategory && form.permissions.editCategory && form.permissions.deleteCategory} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readCategory}
                              onChange={(e) =>
                                setpermission('readCategory', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addCategory}
                              onChange={(e) =>
                                setpermission('addCategory', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editCategory}
                              onChange={(e) =>
                                setpermission('editCategory', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteCategory}
                              onChange={(e) =>
                                setpermission(
                                  'deleteCategory',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Reseller Category</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleResellCateg(e.target.checked)} checked={form.permissions.addResellerCategory && form.permissions.readResellerCategory && form.permissions.editResellerCategory && form.permissions.deleteResellerCategory} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readResellerCategory}
                              onChange={(e) =>
                                setpermission(
                                  'readResellerCategory',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addResellerCategory}
                              onChange={(e) =>
                                setpermission(
                                  'addResellerCategory',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editResellerCategory}
                              onChange={(e) =>
                                setpermission(
                                  'editResellerCategory',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteResellerCategory}
                              onChange={(e) =>
                                setpermission(
                                  'deleteResellerCategory',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>



                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Plan Features</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handlePlanFeature(e.target.checked)} checked={form.permissions.addPlanFeatures && form.permissions.readPlanFeatures && form.permissions.editPlanFeatures && form.permissions.deletePlanFeatures} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readPlanFeatures}
                              onChange={(e) =>
                                setpermission('readPlanFeatures', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addPlanFeatures}
                              onChange={(e) =>
                                setpermission('addPlanFeatures', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editPlanFeatures}
                              onChange={(e) =>
                                setpermission('editPlanFeatures', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deletePlanFeatures}
                              onChange={(e) =>
                                setpermission(
                                  'deletePlanFeatures',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Plan</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handlePlanPre(e.target.checked)} checked={form.permissions.readPlan && form.permissions.addPlan && form.permissions.editPlan && form.permissions.deletePlan} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readPlan}
                              onChange={(e) =>
                                setpermission('readPlan', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addPlan}
                              onChange={(e) =>
                                setpermission('addPlan', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editPlan}
                              onChange={(e) =>
                                setpermission('editPlan', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deletePlan}
                              onChange={(e) =>
                                setpermission(
                                  'deletePlan',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Coupons</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleCoupons(e.target.checked)} checked={form.permissions.readCoupons && form.permissions.addCoupons && form.permissions.editCoupons && form.permissions.deleteCoupons} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readCoupons}
                              onChange={(e) =>
                                setpermission('readCoupons', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addCoupons}
                              onChange={(e) =>
                                setpermission('addCoupons', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editCoupons}
                              onChange={(e) =>
                                setpermission('editCoupons', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteCoupons}
                              onChange={(e) =>
                                setpermission(
                                  'deleteCoupons',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Currency</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleCurrencyPre(e.target.checked)} checked={form.permissions.readCurrency && form.permissions.addCurrency && form.permissions.editCurrency && form.permissions.deleteCurrency} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readCurrency}
                              onChange={(e) =>
                                setpermission('readCurrency', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addCurrency}
                              onChange={(e) =>
                                setpermission('addCurrency', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editCurrency}
                              onChange={(e) =>
                                setpermission('editCurrency', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteCurrency}
                              onChange={(e) =>
                                setpermission(
                                  'deleteCurrency',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Booking System</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleBookingPre(e.target.checked)} checked={form.permissions.addBooking && form.permissions.editBooking && form.permissions.readBooking && form.permissions.deleteBooking && form.permissions.refreshBooking} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readBooking}
                              onChange={(e) =>
                                setpermission('readBooking', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addBooking}
                              onChange={(e) =>
                                setpermission('addBooking', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editBooking}
                              onChange={(e) =>
                                setpermission('editBooking', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteBooking}
                              onChange={(e) =>
                                setpermission(
                                  'deleteBooking',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <span className='mr-2'>
                              <input
                                type="checkbox"
                                checked={form.permissions.refreshBooking}
                                onChange={(e) =>
                                  setpermission(
                                    'refreshBooking',
                                    e.target.checked
                                  )
                                }
                              />
                            </span>

                          </label>
                        </td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Continents</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => HandleContinent(e.target.checked)} checked={form.permissions.readContinents && form.permissions.addContinents && form.permissions.editContinents && form.permissions.deleteContinents} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readContinents}
                              onChange={(e) =>
                                setpermission('readContinents', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addContinents}
                              onChange={(e) =>
                                setpermission('addContinents', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editContinents}
                              onChange={(e) =>
                                setpermission('editContinents', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteContinents}
                              onChange={(e) =>
                                setpermission(
                                  'deleteContinents',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Countries</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleCountriuesPre(e.target.checked)} checked={form.permissions.readCountries && form.permissions.addCountries && form.permissions.editCountries && form.permissions.deleteCountries} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readCountries}
                              onChange={(e) =>
                                setpermission('readCountries', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addCountries}
                              onChange={(e) =>
                                setpermission('addCountries', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editCountries}
                              onChange={(e) =>
                                setpermission('editCountries', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteCountries}
                              onChange={(e) =>
                                setpermission(
                                  'deleteCountries',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>

                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Region</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => HandleRegionPre(e.target.checked)} checked={form.permissions.readRegion && form.permissions.addRegion && form.permissions.editRegion && form.permissions.deleteRegion} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readRegion}
                              onChange={(e) =>
                                setpermission('readRegion', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addRegion}
                              onChange={(e) =>
                                setpermission('addRegion', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editRegion}
                              onChange={(e) =>
                                setpermission('editRegion', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteRegion}
                              onChange={(e) =>
                                setpermission(
                                  'deleteRegion',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>
                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Cities</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => HandleCitiesPre(e.target.checked)} checked={form.permissions.readCities && form.permissions.addCities && form.permissions.editCities && form.permissions.deleteCities} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readCities}
                              onChange={(e) =>
                                setpermission('readCities', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addCities}
                              onChange={(e) =>
                                setpermission('addCities', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editCities}
                              onChange={(e) =>
                                setpermission('editCities', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.deleteCities}
                              onChange={(e) =>
                                setpermission(
                                  'deleteCities',
                                  e.target.checked
                                )
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>
                      <tr>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>Email Template</td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><input type="checkbox" onChange={e => handleEmailPre(e.target.checked)} checked={form.permissions.readEmailTemplate && form.permissions.editEmailTemplate && form.permissions.addEmailTemplate} /></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.readEmailTemplate}
                              onChange={(e) =>
                                setpermission('readEmailTemplate', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.addEmailTemplate}
                              onChange={(e) =>
                                setpermission('addEmailTemplate', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions.editEmailTemplate}
                              onChange={(e) =>
                                setpermission('editEmailTemplate', e.target.checked)
                              }
                            />{' '}

                          </label>
                        </td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>


            {/* <div className="form-row">


              <div className="col-md-6 mb-3">
                <label>
                  Status<span className="star">*</span>
                </label>
                <SelectDropdown
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="Select Status"
                  intialValue={form.status}
                  result={e => { setform({ ...form, status: e.value }) }}
                  options={statusModel.list}
                />
                {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
              </div>
              <div className="col-md-12 mb-3">
                <h5 className='mb-0 mt-4'>Permissions</h5>
                roles
                1st

              </div>
            </div> */}

            <div className="flex items-center justify-end p-4">

              <button type="submit" className="!px-4 text-sm font-normal text-white hover:!text-white hover:!no-underline h-10 flex items-center justify-center gap-2 !bg-[#4b1991] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC]  ">
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditRole;

import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import ImageUpload from "../../components/common/ImageUpload";
import { resellerCategoryType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import SelectDropdown from "../../components/common/SelectDropdown";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import { CountryAlpha2 } from "../../components/common/CountryFile/CountryFile";

const AddEditCountry = () => {
    const [form, setform] = useState({ status: "active" })
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const { id } = useParams()
    const user = useSelector((state) => state.user);
    const [continentdata, setcontinentdata] = useState([]);
    const [currencydata, setcurrencydata] = useState([]);
    const [images, setImages] = useState({ icon: '' });
    //   For Getting Continent data
    const GetContinentData = () => {
        ApiClient.get(`api/continent/listing`, { status: 'active', sortBy: 'name asc' }).then(res => {
            if (res.success == true) {
                setcontinentdata(res.data);
            }
        })
    }
    //  For Getting Currency Data
    const GetCurrencies = () => {
        ApiClient.get(`api/currency/listing`, { status: 'active', sortBy: 'name asc' }).then(res => {
            if (res.success == true) {
                setcurrencydata(res.data)
            }
        })
    }
    useEffect(() => {
        GetContinentData()
        GetCurrencies()
    }, [])

    const formValidation = [
        { key: 'status', required: true },
        { key: 'continent', required: true },
    ]
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        // URL
        let url = 'api/country'
        let value = {
            ...form,
            // ...images
        }
        if (value.id) {
            method = 'put'
            url = 'api/country/update'
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/countries")
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            // API
            ApiClient.get(`api/country/detail?id=${id}`).then(async(res) => {
                if (res.success) {
                    let data = res.data
                    data.continent = data.continent ? data.continent._id : ''
                    data.currencyId = data.currencyId ? data.currencyId._id : null
                    if(data?.icon&&data?.icon!=""){

                    }else{
                        data.icon = data?.isoCode ? data?.isoCode:CountryAlpha2(data?.name)
                    }
                    setform(data)
                    setSubmitted(false)
                    images.icon = data?.icon
                    setImages({ ...images })
                }
                loader(false)
            })
        }
    }, [id])


    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }


    const imageErr = (e) => {
        e.target.src = '/assets/img/placeholder.png'
    }

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">
                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to="/countries" className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {form && form.id ? 'Edit' : 'Add'} Country
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Country</p>
                        </div>
                    </div>




                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Continent<span className="star">*</span></label>
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                placeholder="Select Continent"
                                displayValue="name"
                                intialValue={form.continent}
                                theme="search"
                                result={e => { setform({ ...form, continent: e.value }) }}
                                options={continentdata}
                            />
                            {submitted && !form.continent ? <div className="text-danger">Continent is Required</div> : <></>}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Currency<span className="star">*</span></label>
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                placeholder="Select Currency"
                                displayValue="currency"
                                theme="search"
                                intialValue={form.currencyId}
                                result={e => { setform({ ...form, currencyId: e.value }) }}
                                options={currencydata}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Status<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    displayValue="name"
                                    intialValue={form.status}
                                    result={e => { setform({ ...form, status: e.value }) }}
                                    options={statusModel.list}
                                />
                                {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label className='lablefontcls'>Flag</label>
                            <div className="flex items-center">
                                <input
                                    type="text"
                                    className="form-control mb-3 w-auto"
                                    value={form.icon}
                                    placeholder="Enter Country Code"
                                    onChange={e => setform({ ...form, icon: e.target.value })}
                                    required
                                />
                                <span className="ml-2"> {methodModel.flagIcon(form.icon)}</span>
                            </div>
                            {/* <ImageUpload model="users" result={e => imageResult(e, 'icon')} value={images.icon || form.icon} multiple={false} /> */}
                        </div>
                    </div>
                    <div className="flex justify-end">

                        <button type="submit" className="!px-4 text-sm font-normal text-white hover:!text-white hover:!no-underline h-10 flex items-center justify-center gap-2 !bg-[#4b1991] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] ">Save</button>
                    </div>
                </div>
            </form>
        </Layout>
    </>
}

export default AddEditCountry
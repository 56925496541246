import React from "react";
import methodModel from "../../../methods/methods";
import ImageUpload from "../../../components/common/ImageUpload";
import GooglePlaceAutoComplete from "../../../components/common/GooglePlaceAutoComplete"
import Layout from "../../../components/global/layout";
import SelectDropdown from "../../../components/common/SelectDropdown";
import MultiSelectDropdown from "../../../components/common/MultiSelectDropdown";
import datepipeModel from "../../../models/datepipemodel";
import PhoneInput from "react-phone-input-2";
import { Tooltip } from "antd";
import { useParams } from "react-router-dom";

const Html = ({ addressResult, currency, categories, timezoneLoader, addressSellected, role, form, handleSubmit, setform, roles, submitted, images, imageResult, getError, setEyes, eyes, back, emailCheck, emailErr, emailLoader, detail }) => {
  const {id}=useParams()
  return <>
    <Layout>
      <form onSubmit={handleSubmit} autoComplete="false">
        <div className="pprofile1">


          <div className='flex items-center mb-8'>
            <Tooltip placement="top" title="Back">
              <button onClick={e => back()} className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></button>
            </Tooltip>
            <div>
              <h3 className="text-2xl font-semibold text-[#111827]">
                {id ? 'Edit' : 'Add'} Dazhboards Customer
              </h3>
              <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Dazhboards Customer</p>
            </div>
          </div>




          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6">
              <label>Name<span className="star">*</span></label>
              <input
                type="text"
                className="flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11"
                value={form.fullName}
                onChange={e => setform({ ...form, fullName: e.target.value })}
                required
              />
            </div>

            {role ? <></> : <div className="col-span-12 md:col-span-6">
              <label>Role<span className="star">*</span></label>
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="Select Role"
                intialValue={form.role}
                disabled={form.id ? true : false}
                result={e => { setform({ ...form, role: e.value }) }}
                options={roles}
              />
              {submitted && !form.role ? <div className="invalid-feedback d-block">Role is Required</div> : <></>}
            </div>}

            <div className="col-span-12 md:col-span-6">
              <label>Mobile No<span className="star">*</span></label>
              <PhoneInput
                className=""
                value={form.mobileNo}
                enableSearch={true}
                limitMaxLength
                required
                onChange={e => setform({ ...form, mobileNo: e })}
                countryCodeEditable={true}
              />
              {submitted && getError('dialCode').invalid ? <div className="invalid-feedback d-block">invalid country code</div> : <></>}
              {submitted && getError('mobileNo').invalid && !getError('dialCode').invalid ? <div className="invalid-feedback d-block">Min Length is 10</div> : <></>}
            </div>
            <div className="col-span-12 md:col-span-6">
              <label>Email  {emailLoader ? <span><i className="fa fa-spinner fa-spin"></i></span> : <></>}</label>
              <input
                type="email"
                className="flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11"
                value={form.email}
                autoComplete="false"
                onChange={e => { setform({ ...form, email: e.target.value }); emailCheck(e.target.value) }}
                required
              />
              {emailErr ? <div className="invalid-feedback d-block">{emailErr}</div> : <></>}

            </div>
            <div className="col-span-12 md:col-span-6">
              <label>Password</label>
              <div className="inputWrapper">
                <input
                  type={eyes.password ? 'text' : 'password'}
                  className="flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11"
                  value={form.password}
                  autoComplete="new-password"
                  onChange={e => setform({ ...form, password: e.target.value })}

                />
                <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
              </div>
              {submitted && getError('password').invalid ? <div className="invalid-feedback d-block">Password minimum length should be 8</div> : <></>}
            </div>
            <div className="col-span-12 md:col-span-6">
              <label>Confirm Password {form.password ? <span className="star">*</span> : <></>}</label>
              <div className="inputWrapper">
                <input
                  type={eyes.confirmPassword ? 'text' : 'password'}
                  className="flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11"
                  value={form.confirmPassword}
                  onChange={e => setform({ ...form, confirmPassword: e.target.value })}
                  required={form.password ? true : false}
                />
                <i className={eyes.confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })}></i>
              </div>
              {submitted && getError('confirmPassword').invalid ? <div className="invalid-feedback d-block">Comfirm Password is not matched with Password</div> : <></>}
            </div>

            {/* <div className="col-md-12 mb-3">
                                    <label>AboutUs<span className="star">*</span></label>
                                    <textarea
                                        className="flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11"
                                        value={form.aboutUs}
                                        onChange={e => setform({ ...form, aboutUs: e.target.value })}
                                        required
                                    />
                                </div> */}
            <div className="col-span-12 md:col-span-6">
              <label className='lablefontcls'>Image</label><br></br>
              <ImageUpload model="users" result={e => imageResult(e, 'image')} value={images.image || form.image} multiple={false} />
            </div>

          </div>

          <h5>Company Profile</h5>

          <div className="grid grid-cols-12 gap-4 mt-2 mb-3">
            <div className="col-span-12 md:col-span-6">
              <label className='user_company'>
                Company Name
              </label>
              <div className='col-span-12 md:col-span-12'>
                <input type='text' className='flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11'
                  placeholder='Company name'
                  value={form.companyName}
                  onChange={e => setform({ ...form, companyName: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <label className='user_company'>
                Contact Phone Number
              </label>
              <div className="mobile_number">
                <PhoneInput
                  className=""
                  value={form.companymobileno}
                  enableSearch={true}
                  limitMaxLength
                  required
                  onChange={e => setform({ ...form, companymobileno: e })}
                  countryCodeEditable={true}
                />

                {submitted && getError('companydialcode').invalid ? <div className="invalid-feedback d-block">invalid country code</div> : <></>}
                {submitted && getError('companymobileno').invalid && !getError('companydialcode').invalid ? <div className="invalid-feedback d-block">Min Length is 10</div> : <></>}

              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <label className='user_company'>
                Company Email
              </label>
              <div className='col-span-12 md:col-span-12'>
                <input type='email' className='flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11' placeholder='Contact email'
                  value={form.contactemail}
                  onChange={e => setform({ ...form, contactemail: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>

          <h5>Company Information</h5>
          <div className='grid grid-cols-12 gap-4'>
            <div className="col-span-12 md:col-span-6">
              <label>Address<span className="star">*</span></label>
              <GooglePlaceAutoComplete
                value={form.companyAddress}
                result={addressResult}
                id="address"
                placeholder=""
              />
              {!addressSellected && form.companyAddress ? <div className="invalid-feedback d-block">Please Select Location Suggestion</div> : <></>}
            </div>
            <div className='col-span-12 md:col-span-6'>
              <label>Contact Name</label>
              <input type='text' className='flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11' placeholder='Contact name'
                value={form.contactName}
                onChange={e => setform({ ...form, contactName: e.target.value })}
                required
              />
            </div>
            <div className='col-span-12 md:col-span-6'>
              <label> Time Zone {timezoneLoader ? <><i className='fa fa-spinner fa-spin'></i></> : <></>}</label>
              <input type='text' className='flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11' placeholder='Time zone'
                value={form.companytimezone}
                onChange={e => setform({ ...form, companytimezone: e.target.value })}
                required
                disabled
              />
            </div>
            <div className='col-span-12 md:col-span-6'>
              <label>Currency</label>
              <MultiSelectDropdown
                id="currency"
                displayValue="currency"
                intialValue={form.currency}
                result={e => setform({ ...form, currency: e.value })}
                options={currency}
              />
            </div>
            <div className='col-span-12 md:col-span-6'>
              <label>Product Categories</label>
              <MultiSelectDropdown
                options={categories}
                intialValue={form.productcategory}
                displayValue="name"
                id="productCat"
                result={e => setform({ ...form, productcategory: e.value })}
              />
            </div>
            <div className='col-span-12 md:col-span-6'>
              <label>Website URL</label>
              <input type='text' className='flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11' placeholder='Website URL'
                value={form.website}
                onChange={e => setform({ ...form, website: e.target.value })}
                required
              />
            </div>
            <div className='col-span-12 md:col-span-6'>
              <label>Financial Year Start</label>
              {/* <input type='text' className='flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11' placeholder='2023'
                      value={form.financialYear}
                      maxLength="4"
                      minLength="4"
                      onChange={e => setform({ ...form, financialYear: methodModel.isNumber(e) })}
                      required
                    /> */}
              <SelectDropdown
                id="financialYear"
                displayValue="name"
                placeholder="Select Month"
                name="financialYear"
                required={true}
                intialValue={form.financialYear}
                result={e => { setform({ ...form, financialYear: e.value }) }}
                options={datepipeModel.monthArray.map(itm => {
                  return {
                    ...itm,
                    id: itm.id + 1
                  }
                })}
              />
            </div>

          </div>
          <div className="flex justify-end mt-6">

            <button type="submit" className="!px-4 text-sm font-normal text-white hover:!text-white hover:!no-underline h-10 flex items-center justify-center gap-2 !bg-[#4b1991] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] ">Save</button>
          </div>
        </div>

      </form>
    </Layout>
  </>
}

export default Html
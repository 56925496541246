import React from 'react';
import methodModel from '../../../methods/methods';
import { Link } from 'react-router-dom';
import './style.scss';
import PhoneInput from 'react-phone-input-2';

const Html = ({ handleSubmit, setForm, form, getError, uploadImage, submitted }) => {
  return (
    <>

      <div className='wrapper_section'>
        <div className='flex items-center  justify-between'>
          <h3 className='text-2xl font-semibold text-[#111827]'>Edit Profile </h3>

        </div>


        <form name="profileForm" className="" onSubmit={handleSubmit} >


          <div className='p-6 shadow-box border !border-grey rounded-large bg-white mt-6 max-w-[1020px]'>
            <div className='grid items-center grid-cols-12 gap-4'>
              <div className='col-span-12 md:col-span-2'>
                <div className='text_head'>
                  <label className='text-typo text-sm font-medium max-w-sm w-full block' >Image</label>
                </div>
              </div>
              <div className='col-span-12 md:col-span-10'>
                <div className='sub_fatch flex items-center'>
                  <label className=" mr-6">
                    <img src={methodModel.userImg(form && form.image)} className="h-28 w-28 rounded-lg object-cover" />
                  </label>
                  <div className='profile_btn '>

                    <div>
                      <label className="text-white bg-primary focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2 cursor-pointer">
                        <input
                          id="bannerImage"
                          type="file"
                          className="d-none"
                          accept="image/*"
                          value={form.baseImg ? form.baseImg : ''}
                          onChange={(e) => { uploadImage(e); }}
                        />{form.image ? 'Change' : 'Upload'} Image</label>
                    </div>
                    <div>
                      {form.image ? <label className="text-black text-sm ml-3" onClick={e => setForm({ ...form, image: "" })}>Remove Image</label> : <></>}
                    </div>
                    {/* <input type="hidden" name='image' required value={form.image} /> */}
                    {submitted && getError('image')?.invalid ? <div className="invalid-feedback d-block">Image is required</div> : <></>}
                  </div>
                </div>
              </div>


              <div className='col-span-12 md:col-span-2'>
                <div className='text_head'>
                  <label className='text-typo text-sm font-medium max-w-sm w-full block'>Name</label>
                </div>
              </div>
              <div className='col-span-12 md:col-span-10'>
                <div className='sub_fatch'>
                  <div>
                    <input
                      type="text"
                      className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                      placeholder="Enter Name"
                      name='fullName'
                      value={form.fullName}
                      onChange={e => setForm({ ...form, fullName: e.target.value })}
                      required
                    />
                    {submitted && getError('fullName')?.invalid ? <div className="invalid-feedback d-block">Name is required</div> : <></>}
                  </div>
                </div>
              </div>


              <div className='col-span-12 md:col-span-2'>
                <div className='text_head'>
                  <label className='text-typo text-sm font-medium max-w-sm w-full block'>Email</label>
                </div>
              </div>
              <div className='col-span-12 md:col-span-10'>
                <div className='sub_fatch'>
                  <div>
                    <input
                      type="email"
                      className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                      placeholder="Enter Name"
                      value={form.email ? form.email : ''}
                      disabled
                    />
                  </div>
                </div>
              </div>


              <div className='col-span-12 md:col-span-2'>
                <div className='text_head'>
                  <label className='text-typo text-sm font-medium max-w-sm w-full block'>Mobile No</label>
                </div>
              </div>
              <div className='col-span-12 md:col-span-10'>
                <div className='sub_fatch'>
                  <div className='reactphone mobile_number'>
                    <PhoneInput
                      className="w-full"
                      value={form.mobileNo}
                      enableSearch={true}
                      limitMaxLength
                      required
                      onChange={e => setForm({ ...form, mobileNo: e })}
                      countryCodeEditable={true}
                    />
                    {/* <div className="phoneInput">
                <input
                  type="text"
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary" placeholder='+60'
                  name='dialCode'
                  value={form && form.dialCode || ''}
                  dialCode="true"
                  title="Phone number with + and remaing 9 digit with 0-9"
                  maxLength={4}
                  onChange={e => setForm({ ...form, dialCode: e.target.value })}
                  required
                />
                <input
                  type="text"
                  name='mobileNo'
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary" placeholder='Mobile No.'
                  value={form && form.mobileNo}
                  minLength="10"
                  maxLength={12}
                  onChange={e => setForm({ ...form, mobileNo: methodModel.isNumber(e) })}
                  required
                />
              </div> */}
                    {submitted && getError('dialCode').invalid ? <div className="invalid-feedback d-block">invalid country code</div> : <></>}
                    {submitted && getError('mobileNo').invalid && !getError('dialCode').invalid ? <div className="invalid-feedback d-block">Min Length is 10</div> : <></>}
                  </div>
                </div>
              </div>


              <div className='col-span-12 md:col-span-2'>
                <div className='text_head'>
                  <label className='text-typo text-sm font-medium max-w-sm w-full block'>Role</label>
                </div>
              </div>
              <div className='col-span-12 md:col-span-10'>
                <div className='sub_fatch'>
                  <div>
                    <input
                      type="text"
                      className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                      placeholder="Enter Name"
                      value={form.role}
                      disabled
                    />
                  </div>
                </div>
              </div>




              <div className='col-span-12 sm:mt-4 md:mt-4'>
                <div className='flex justify-end gap-3  items-end'>

                  <Link to="/profile" className="cursor-pointer relative rounded-large bg-[#fff] !px-4 text-sm text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed ">
                    Discards
                  </Link>
                  <button type="submit" className="!px-4 text-sm font-normal text-white hover:!text-white hover:!no-underline h-10 flex items-center justify-center gap-2 !bg-[#4b1991] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] ">
                    Update
                  </button>
                </div>
              </div>

            </div>
          </div>

        </form>





      </div>


    </>
  );
};

export default Html;

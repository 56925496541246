let host = document.location.host;
let apiUrl ='https://endpoint.dazhboards.com/'
if(host.includes('localhost')){
  apiUrl='http://endpoint.jcsoftwaresolution.com:6030/'
  apiUrl='https://endpoint.dazhboards.com/'
}else if(host.includes('stage.dazhboards.com/')){
  apiUrl='https://endpoint.dazhboards.com/'
}else if(host.includes('jcsoftwaresolution.com')){
  apiUrl='http://endpoint.jcsoftwaresolution.com:6030/'
}else{
  apiUrl='https://endpoint.dazhboards.com/'
}

const environment = {
    // api: apiUrl,
  api: 'https://endpoint.dazhboards.com/',
    // map_api_key:'AIzaSyCbRhC6h9Pp43-5t_Knyrd_ewAdLMIJtCg',
    map_api_key:'AIzaSyAID0kSQQmScLcv5PornXUOEG0LPn8hfKQ',
    planTypeId:'64ad2ebce5aa778d26a54f02',
    userRoleId:'64b15102b14de6c28838f7d2',
    affiliateRoleId:'6667fccc4e936c987f3b4f66',
    sustainableId:"64b149e3cd1f5ed827c13812",
    adminRoleId:'64b152a909d268f038611929',
    resellerTypeId:'64b23b7d690d1d5f7ae76102',
    productTypeId:'64a7d198fa039f179c0320ca'
  };
  export default environment;

import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import { emailType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Editor } from "@tinymce/tinymce-react";
import { Tooltip } from "antd";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const AddEditEmailTemplate = () => {
    const defaultvalue = () => {
        let keys = { ...emailType }
        Object.keys(emailType).map(itm => {
            if (itm != 'permissions') keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const { id } = useParams()
    const [form, setform] = useState(emailType)
    const [tab, setTab] = useState(methodModel.getPrams('selectTab')?'other':'form')
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const specialChars = useRef([])
    const [variables, setVariables] = useState([])
    const [htmlCode, setHtmlCode] = useState(false)
    const formValidation = [
        { key: 'subject', required: true },
    ]

    const handleSubmit = (e) => {
        if (e) e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) {
            setTab('form')
            return
        }
        let method = 'post'
        let url = 'api/email'
        let value = {
            ...form,
        }
        if (value.id) {
            method = 'put'
            url = `api/update/template?id=${value.id}`
        } else {
            delete value.id
        }
        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/emailtemplate")
            }
            loader(false)
        })
    }
    const dirtyHtml = form.content
    const trustedTypesPolicy = {
        createHTML(s) { return s; },
        createScriptURL(s) { return s; },
      };
      
      const cleanHtml = DOMPurify.sanitize(dirtyHtml, {
        TRUSTED_TYPES_POLICY: trustedTypesPolicy,
      });
      
      const parsedHtml = parse(cleanHtml);
    const getConstants = () => {
        ApiClient.get('api/constants').then(res => {
            if (res.success) {
                let data = res.data.map(itm => {
                    return {
                        text: itm, value: itm
                    }
                })
                setVariables([...data])
                specialChars.current = data
            }
        })
    }

    useEffect(() => {
        // getConstants()
        if (id) {
            loader(true)
            ApiClient.get('api/template', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = emailType
                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    setform({
                        ...payload
                    })
                    setVariables([...value?.constants])
                                }
                loader(false)
            })
        } else {
            setform(defaultvalue())
        }
    }, [id])

    const onSelect = (e) => {
    }

    const onRemove = (e) => {
    }



    const textAreaRef = useRef(null);

    const insertVariable = (variable) => {
        try{        const textarea = textAreaRef.current;
        const cursorPos = textarea.selectionStart;
            const end = textarea.selectionEnd;
        const textBeforeCursor = form.content.substring(0, cursorPos);
        const textAfterCursor = form.content.substring(cursorPos, form.content.length);

        // Insert the variable at the cursor position
        const updatedText=textBeforeCursor +"{"+ variable+"}" + textAfterCursor
        textarea.value=updatedText
        setform({...form,content:updatedText})

            

            // Ensure the textarea maintains focus after insertion
            textarea.focus();
            textAreaRef.current.selectionEnd = end + variable.length+2;
             

        }catch(err){
            console.log(err)
        }
    };

    return <>
        <Layout>
            {tab == 'form' ? <>
                <form onSubmit={handleSubmit}>
                    <div className="pprofile1">

                        <div className='flex items-center mb-8'>
                            <Tooltip placement="top" title="Back">
                                <Link to="/emailtemplate" className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></Link>
                            </Tooltip>
                            <div>
                                <h3 className="text-2xl font-semibold text-[#111827]">
                                    {form && form.id ? 'Edit' : 'Add'} Email
                                </h3>
                                <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Email</p>
                            </div>
                        </div>




                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-12">
                                <label>Title<span className="star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={form.title}
                                    onChange={e => setform({ ...form, title: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="col-span-12 md:col-span-12">
                                <label>Subject<span className="star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={form.subject}
                                    onChange={e => setform({ ...form, subject: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="col-span-12 md:col-span-12">
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-12 md:col-span-8">

                                        <label>Body<span className="star">*</span></label>

                                        <ul class="nav nav-tabs">
                                            <li className="nav-item">
                                                <a className={`nav-link ${htmlCode ? 'active' : ''}`} onClick={e => setHtmlCode(true)}>Html Code</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${!htmlCode ? 'active' : ''}`} onClick={e => setHtmlCode(false)}>Editor</a>
                                            </li>
                                        </ul>
                                        {htmlCode ? <>
                                            <textarea ref={textAreaRef} className="form-control htmlCode" value={form.content} onChange={e => setform({ ...form, content: e.target.value })}></textarea>
                                        </> : <>
                                                <Editor ref={textAreaRef} textareaName='content' value={form.content ? form.content : ''} className='tuncketcls'
                                                onEditorChange={(newValue, editor) => {
                                                    setform({ ...form, content: newValue })
                                                }}
                                                    apiKey="umvdq7vkiacu5ldyurqgqxct553ehzvebsrsccd42pna9x99"

                                                init={{
                                                    selector: 'textarea#autocompleter-cardmenuitem',
                                                    height: 800,
                                                    setup: (editor) => {
                                                        const onAction = (autocompleteApi, rng, value) => {
                                                            editor.selection.setRng(rng);
                                                            editor.insertContent(value);
                                                            autocompleteApi.hide();
                                                        };
                                                        const getMatchedChars = (pattern) => {
                                                            return specialChars.current.filter(char => char.text.indexOf(pattern) !== -1);
                                                        };

                                                        editor.ui.registry.addAutocompleter('specialchars_cardmenuitems', {
                                                            trigger: '$',
                                                            minChars: 0,
                                                            columns: 1,
                                                            highlightOn: ['char_name'],
                                                            onAction: onAction,
                                                            fetch: (pattern) => {
                                                                return new Promise((resolve) => {

                                                                    const results = getMatchedChars(pattern).map(char => ({
                                                                        type: 'cardmenuitem',
                                                                        value: '${' + char.value + '}',
                                                                        label: char.text,
                                                                        items: [
                                                                            {
                                                                                type: 'cardcontainer',
                                                                                direction: 'vertical',
                                                                                items: [
                                                                                    {
                                                                                        type: 'cardtext',
                                                                                        text: char.text,
                                                                                        name: 'char_name'
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }));
                                                                    resolve(results);
                                                                });
                                                            }
                                                        });
                                                    }
                                                }}
                                                required
                                            />
                                        </>}

                                    </div>
                                    <div className="col-span-12 md:col-span-4">
                                        <div className="shadow p-3">
                                            <h5>Variables</h5>
                                            <ul>
                                                {variables.map(itm => {
                                                    return <li onClick={e => insertVariable(itm)}>{itm}</li>
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="text-right">

                            <button type="button" className="btn btn-primary mr-2" onClick={e => setTab('preview')}>Preview</button>
                            <button type="submit" className="btn btn-primary">Save</button>
                        </div>
                    </div>


                </form>
            </> : <>
                <div className="pprofile1">
                    <h3 className="ViewUser mb-3">Preview</h3>
                    <div className="p-2 border"
                   
                    > {parsedHtml}</div>
                    <div className="text-right mt-3">
                            <button type="button" className="btn btn-secondary discard mr-2" onClick={e => { if (methodModel.getPrams('selectTab')){
                                history(-1)
                            }else{  setTab('form')}}}>Back</button>
                            {methodModel.getPrams('selectTab')?null:<button className="btn btn-primary" onClick={e => handleSubmit()}>Save</button>}
                    </div>
                </div>
            </>}

            <div>
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Body</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <span > {parsedHtml}</span>
                               
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>
}

export default AddEditEmailTemplate
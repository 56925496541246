import React, { useState, useEffect } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../../methods/methods";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useNavigate, useParams } from "react-router-dom";

import addressModel from "../../../models/address.model";
import environment from "../../../environment";

const AddEdit = () => {
    const { role, id } = useParams()
    const [images, setImages] = useState({ image: '', logo: '' });
    const defaultvalue = userType
    const [form, setform] = useState({ role, mobileNo: '+61' })
    const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
    const [submitted, setSubmitted] = useState(false)
    const history = useNavigate()
    const [roles, setRoles] = useState([])
    const [emailLoader, setEmailLoader] = useState(false)
    const [emailErr, setEmailErr] = useState('')
    const [detail, setDetail] = useState()
    const [timezoneLoader, setTimezoneLoader] = useState(false)
    const [addressSellected, setAddressSellected] = useState(true)
    const [channelsCategories, setChannelsCategories] = useState([])
    const [categories, setCategories] = useState([])
    const [affiliateTypeCategories, setAffiliateTypeCategories] = useState([])
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'mobileNo', minLength: 10 },
        { key: 'password', minLength: 8 },
        { key: 'confirmPassword', minLength: 8, confirmMatch: ['confirmPassword', 'password'] },
        { key: 'dialCode', dialCode: true },
        { key: 'companymobileno', minLength: 10 },
        { key: 'companydialcode', companydialcode: true }
    ]
    const [platforms, setPlatforms] = useState([
        {
            name: '',
            url: '',
        }
    ]);

    const updatePlatforms = (updatedPlatforms) => {
        setPlatforms(updatedPlatforms);
    };

    const getError = (key = "") => {
        return methodModel.getError(key, form, formValidation)
    }

    const handleAddPlatform = () => {
        const newPlatform = {
            name: '',
            url: '',
        };

        const updatedPlatforms = [...platforms, newPlatform];
        updatePlatforms(updatedPlatforms);
    };

    const handlePlatformNameChange = (index, value) => {
        const updatedPlatforms = [...platforms];
        updatedPlatforms[index].name = value;
        updatePlatforms(updatedPlatforms);
    };

    const handlePlatformUrlChange = (index, value) => {
        const updatedPlatforms = [...platforms];
        updatedPlatforms[index].url = value;
        updatePlatforms(updatedPlatforms);
    };

    const handleRemovePlatform = (index) => {
        const updatedPlatforms = [...platforms];
        updatedPlatforms.splice(index, 1);
        updatePlatforms(updatedPlatforms);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        if(methodModel.emailvalidation(form?.email) == false) return
        let method = 'post'
        let url = 'api/add/user'
        let value = {
            ...form,
            ...images,
            social_profiles:platforms,
            role: "6667fccc4e936c987f3b4f66"
        }
        if (id) {
            method = 'put'
            value['id'] = id
            url = 'api/user/profile'
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                let url = '/affiliates'
                history(url)
            }
            loader(false)
        })
    }

    const getAffiliateTypeCategories = () => {
        ApiClient.get(`api/categories/listing?catType=6669824b78d69423b79dbc81`).then(res => {
            if (res.success) {
                setAffiliateTypeCategories(res.data)
            }
        })
    }

    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }

    const back = () => {
        history(-1)
    }


    const getRoles = () => {
        ApiClient.get('api/roles/listing', { status: 'active' }).then(res => {
            if (res.success) {
                setRoles(res.data)
            }
        })
    }


    const emailCheck = (email) => {
        let isValid = methodModel.emailvalidation(email)
        if (isValid) {
            setEmailLoader(true)
            ApiClient.get('api/check/email', { email: email }).then(res => {
                if (!res.success) {
                    if (id) {
                        if (detail) {
                            if (detail?.email != email) {
                                setEmailErr(res.error.message)
                            }
                        }
                    } else {
                        setEmailErr(res.error.message)
                    }
                } else {
                    setEmailErr('')
                }
                setEmailLoader(false)
            })
        }
    }

    useEffect(() => {
        setSubmitted(false)

        if (id) {
            loader(true)
            ApiClient.get("api/user/profile", { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    setDetail(value)
                    let payload = defaultvalue
                    let oarr = Object.keys(defaultvalue)
                    oarr.map(itm => {
                        payload[itm] = value[itm] || ''
                    })
                    payload.role = value.role._id
                    payload.id = value?.id || value?._id
                    setform({ ...payload })
                    images.image = payload?.image
                    images.logo = payload?.logo
                    setImages(images)
                    setPlatforms(value?.social_profiles)
                }
                loader(false)
            })
        }
        getRoles()
        getChannelsCategories()
        getCategories()
        getAffiliateTypeCategories()
    }, [id])

    const addressResult = async (e) => {
        let address = {}
        if (e.place) {
            address = addressModel.getAddress(e.place)
            setAddressSellected(true)
        } else {
            setAddressSellected(false)
        }
        setform({
            ...form,
            companyAddress: e.value,
            country: address.country || '',
            city: address.city || '',
            state: address.state || '',
            zipcode: address.zipcode || '',
            lat: address.lat || '',
            lng: address.lng || ''
        })
        if (e.place) {
            setTimezoneLoader(true)
            const apires = await addressModel.gettimeZone(e.place)
            setTimezoneLoader(false)
            setform({
                ...form,
                companyAddress: e.value,
                country: address.country || '',
                city: address.city || '',
                state: address.state || '',
                zipcode: address.zipcode || '',
                lat: address.lat || '',
                lng: address.lng || '',
                companytimezone: apires?.data?.timeZoneId || ''
            })
        }
    }

    const getCategories = () => {
        ApiClient.get('api/categories/listing', { catType: environment.productTypeId, status: 'active' }).then(res => {
            if (res.success) {
                setCategories(res.data)
            }
        })
    }

    const getChannelsCategories = () => {
        ApiClient.get(`api/categories/listing?catType=6668159767441407842c2407`).then(res => {
            if (res.success) {
                setChannelsCategories(res.data)
            }
        })
    }

    return <>
        <Html
            addressResult={addressResult}
            channelsCategories={channelsCategories}
            categories={categories}
            timezoneLoader={timezoneLoader}
            addressSellected={addressSellected}
            form={form}
            detail={detail}
            emailCheck={emailCheck}
            emailLoader={emailLoader}
            emailErr={emailErr}
            back={back}
            setEyes={setEyes}
            eyes={eyes}
            role={role}
            setform={setform}
            roles={roles}
            submitted={submitted}
            images={images}
            handleSubmit={handleSubmit}
            imageResult={imageResult}
            getError={getError}
            affiliateTypeCategories={affiliateTypeCategories}
            handleAddPlatform={handleAddPlatform}
            handlePlatformNameChange={handlePlatformNameChange}
            handlePlatformUrlChange={handlePlatformUrlChange}
            handleRemovePlatform={handleRemovePlatform}
            platforms={platforms}
        />
    </>
}

export default AddEdit